.App {
  background-color: #282c34; 
}

:root {
  --primary: #4c00a3;
  --secondary: #2d004b;
  
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

/* Scrollbar Styles */

/* Chromium */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  background: var(--secondary);
}

/* Firefox (and half-chromium ig) */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--secondary);
}

body {
  background-color: #121212; /* Dark background for the entire app */
  color: #e0e0e0; /* Light text color */
  font-family: 'Roboto', Arial, sans-serif; /* Modern font */
  margin: 0;
  padding: 0;
  line-height: 1.6; /* Improves readability */
}

.url-list {
  padding: 20px;
  max-width: 1000px; /* Increase max-width for more room for grid */
  margin: 40px auto;
  background-color: #1e1e1e;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.url-list:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.8);
}

.url-list h2 {
  text-align: center;
  color: #f0f0f0;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.url-list ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 20px; /* Spacing between grid items */
  padding: 0;
  list-style-type: none;
}

.url-list li {
  background-color: #252525;
  border: 1px solid #444;
  border-radius: 6px;
  padding: 20px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.url-list li:hover {
  background-color: #333;
  transform: translateY(-2px);
}

.url-list li a {
  text-decoration: none;
  color: #80d8ff;
  font-weight: bold;
  font-size: 1.1rem;
  transition: color 0.2s ease;
}

.url-list li a:hover {
  color: #40c4ff;
}

.url-list li code {
  background-color: #333;
  color: #ffcc80;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
}

.url-list-item {
  word-wrap: break-word; /* Ensures long URLs wrap within the box */
  overflow-wrap: break-word; /* Additional support for URL wrapping */
}

.url-link {
  display: inline-block; /* Makes the URL wrap and behave like a block */
  max-width: 100%; /* Prevents the URL from extending beyond the container */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds '...' if the URL is too long */
  white-space: nowrap; /* Prevents the URL from breaking into multiple lines */
}